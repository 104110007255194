import {noView, InlineViewStrategy, BindingEngine, inject} from 'aurelia-framework';
import * as _ from 'lodash';

@noView
@inject(BindingEngine)
export class FormLayout
{

    activate(field)
    {
        this.config = field;
        this.fields = field.fields;

        this.grouped = this.groupFieldsByColumn(this.config, this.fields);

        field.addRemoveCallback = this._update.bind(this);
    }

    _update()
    {
        this.grouped = this.groupFieldsByColumn(this.config, this.fields);
    }

    groupFieldsByColumn(config, fields)
    {
        let columns = config.columns || [];
        let returnValue = [];

        for (let field of fields) {

            let layoutColumn = field.layoutColumn || 0;

            if (!returnValue[layoutColumn]) {
                returnValue[layoutColumn] = {
                    columns: columns[layoutColumn] || 'col-xs-12',
                    fields: [],
                };
            }

            returnValue[layoutColumn].fields.push(field);
        }

        return returnValue;
    }
}
