import {bindable, customElement, inject, LogManager} from "aurelia-framework";
import {ConfigurationLoader} from "./loader/configuration-loader";
import {DataLoader} from "./loader/data-loader";
import {SubmitHandler} from "./submit/submit-handler";
import {FlashService} from "../flash/flash-service";
import * as _ from "lodash";
import {FormServiceFactory} from "./service/form-service-factory";
import {EventAggregator} from "aurelia-event-aggregator";
import {Router} from 'aurelia-router';
import {Client} from '../api/client';
import {WorkflowService} from '../workflow/workflow-service';
import {SubmitErrorHandler} from "./submit-error-handler.js";
import {BindingSignaler} from "aurelia-templating-resources";

import "./form.less";

const logger = LogManager.getLogger('Form');

@customElement('sio-form')
@inject(
    ConfigurationLoader,
    DataLoader,
    SubmitHandler,
    FlashService,
    FormServiceFactory,
    EventAggregator,
    Client,
    Router,
    WorkflowService,
    SubmitErrorHandler,
    BindingSignaler
)
export class Form {

    @bindable data = {};
    @bindable params = {};
    @bindable config;
    @bindable contextObjectRef;
    @bindable hideSubmit = false;
    @bindable hideGoToDetailButton = false;
    @bindable disableLocalStorage = false;

    @bindable submit;

    _objectIdentifier;
    _isSubmitting = false;

    formContainer;

    loading = true; // When form is loading some data like new configuration, this field is true. Otherwise, false.

    constructor(
        configLoader,
        dataLoader,
        submitHandler,
        flash,
        formServiceFactory,
        ea,
        client,
        router,
        workflowService,
        submitErrorHandler,
        signaler
    ) {
        this.configLoader = configLoader;
        this.dataLoader = dataLoader;
        this.submitHandler = submitHandler;
        this.flash = flash;
        this.formServiceFactory = formServiceFactory;
        this.ea = ea;
        this.client = client;
        this.router = router;
        //Fix, Note needed in workflow-action-handler submit handler
        this.workflowService = workflowService;
        this.submitErrorHandler = submitErrorHandler;
        this.signaler = signaler;

        this.debouncedReloadDynamicConfig = _.debounce(this._reloadDynamicConfig, 250);
        
    }

    detached()
    {
        this.ea.publish('sio_form_cancel', {form: this});
    }

    bind(context, overrideContext)
    {
        this.loading = true;

        logger.debug('Bind', overrideContext);

        let data = this.data;

        if ('string' === typeof data) {
            data =  {id: data};
        } else if (!data) {
            data = {};
        }

        // @fixme why do we have contextObjectRef in both params[] and contextObjectRef?
        // do we really need to have them in two places? what are scenarios when contextObjectRef is
        // not available from params?
        if (
            this.params &&
            this.params.contextObjectRef == null &&
            this.contextObjectRef != null
        ) {
            // Override contextObjectRef that was set in params with the one that was bound directly to form
            if (this.contextObjectRef.contextObjectRef) {
                this.params.contextObjectRef = this.contextObjectRef.contextObjectRef;
            } else {
                this.params.contextObjectRef = this.contextObjectRef;
            }
        }

        if (this.contextObjectRef) {
            data.contextObj = this.contextObjectRef;
        }

        console.log('DATA', data, this.params, this.contextObjectRef);

        this.configLoader.get(this.config, data).then(config => {

            logger.debug('Config loaded', data, config, this.params, this.contextObjectRef);

            if (config.modelId) {

                this.client.get(config.modelId + '/list', 60).then(model => {
                    logger.info('model', model);
                    if (model.displayView) {
                        this.displayView = model.displayView;
                    }
                }, error => {
                    logger.info('Model could not be loaded');
                });
            }

            this.dataLoader.get(this.data, config, this.params).then(data => {

                this._objectIdentifier = data.id;

                this.formService = this.formServiceFactory.getFormService(
                    config, _.cloneDeep(data), this.contextObjectRef
                );

                this.oldLocalFormValue = this.getLocalValue();

                this.formService.changeCallback = this.formValueChanged.bind(this);
                this.formService.form = this;
                this.formService.reference = {id: data.id, modelId: data.modelId};

                if (this.formService.rootConfig.dynamicFields && this.formService.rootConfig.dynamicFields.length > 0) {
                    this._reloadDynamicConfig(data);
                }

                logger.debug('Loaded initial form data', data, this.formService);

                this.loading = false;

                this.ea.publish('sio_form_init_finished', {form: this});
            });

        }, error => {

            logger.error('Error loading configuration', error);
        });
    }

    dataChanged()
    {
        this.bind();
    }

    getFormIdentifier()
    {
        return 'form-value-'
            + this.formService.config.moduleId
            + '-' + this.formService.config.id
            + '-' + ('string' === typeof this.data ? this.data : this.data?.id)
            + (this.contextObjectRef ? '-' + this.contextObjectRef.id : '');
    }

    restoreLocalData()
    {
        this.formService.setValue(this.oldLocalFormValue.value);
        this.oldLocalFormValue = null;

        let dynamicFields = this.formService.rootConfig.dynamicFields;

        if (
            dynamicFields && dynamicFields.length > 0
        ) {
            this.loading = true;
            this.debouncedReloadDynamicConfig(this.formService.getValue());
            this.loading = false;
        }
    }

    resetLocalValue()
    {
        localStorage.removeItem(this.getFormIdentifier());
        this.oldLocalFormValue = null;
    }

    getLocalValue()
    {
        if (this.disableLocalStorage) {
            return null;
        }

        let value = localStorage.getItem(this.getFormIdentifier());

        if (value !== null) {
            value = JSON.parse(value);
        }

        return value;
    }

    saveLocalValue(value)
    {
        if (this.disableLocalStorage) {
            return null;
        }

        localStorage.setItem(this.getFormIdentifier(), JSON.stringify({value, date: new Date()}));
    }

    formValueChanged(field)
    {
        logger.debug('Value Changed', field);

        this.formContainer.dispatchEvent(new CustomEvent('sio-form-value-changed', {
            bubbles: true,
            detail: {form: this, field: field}
        }));

        this.signaler.signal('sio_form_value_changed');
        this.ea.publish('sio_form_value_changed', {form: this, field: field});

        if (field.property) {
            this.signaler.signal('sio_form_value_changed.' + field.property);
        }

        let newValue = this.formService.getValue();

        this.saveLocalValue(newValue);

        // Verify if form configuration should be updated due to change of dynamic field value

        let dynamicFields = this.formService.rootConfig.dynamicFields;

        if (
            dynamicFields &&
            dynamicFields.includes(
                field.fullProperty.replace(/\.\d+\./, '.').replace(/\.\d+$/, '')
            )
        ) {
            this.loading = true;
            this.debouncedReloadDynamicConfig(newValue);
            this.loading = false;
        }
    }

    _reloadDynamicConfig(currentFormValue)
    {
        let dynamicFields = this.formService.rootConfig.dynamicFields.slice(0).concat(['id', 'modelId']);

        logger.debug('Dynamically updating form. Current form value is -> ', currentFormValue);
        logger.debug('Dynamically updating form. Dynamic fields are next -> ', dynamicFields);

        let configParams = {};
        _.each(dynamicFields, (field) => {
            const fieldValue = _.get(currentFormValue, field, undefined);
            _.set(configParams, field, fieldValue);
        });

        if (this.contextObjectRef) {
            configParams.contextObj = this.contextObjectRef;
        }

        if (this.abortController) {
            this.abortController.abort();
        }

        this.abortController = new AbortController();
        let cancelled = false;

        const onAbort = () => {
            cancelled = true;
        }
        this.abortController.signal.addEventListener('abort', onAbort, {once: true});

        logger.debug('Dynamically updating form. Query params are next -> ', configParams);

        this.configLoader.get(this.config, configParams).then(config => {

            if (cancelled) {
                return;
            }

            logger.debug('Dynamic update', config, currentFormValue, this.contextObjectRef);

            _.forEach(this.formService.config.fields, oldField => {
                const newField = _.find(config.fields, (field) => field.property === oldField.property);

                if (!newField) {
                    logger.debug('Update dynamic form default value: Field not found', oldField.property);
                    return true;
                }

                const oldDefault = (oldField.default != null ? oldField.default : null);
                const newDefault = (newField.default != null ? newField.default : null);

                const defaultValueInFieldConfigChanged = !_.isEqual(oldDefault, newDefault);
                const userMadeChanges = !_.isEqualWith(
                    oldDefault,
                    currentFormValue[oldField.property],
                    (defVal, curVal) => {
                        const comparisonFunc = (defVal, curVal) => {
                            if (!_.isObject(curVal) || !_.isObject(defVal)) {
                                return curVal == defVal;
                            }

                            return !_.some(curVal, (value, key) => {
                                return !comparisonFunc(defVal[key], curVal[key]);
                            });
                        };

                        return comparisonFunc(defVal, curVal);
                    }
                );

                if (
                    defaultValueInFieldConfigChanged &&
                    !userMadeChanges
                ) {
                    logger.debug('Update dynamic form default value',
                        defaultValueInFieldConfigChanged,
                        userMadeChanges,
                        oldDefault,
                        currentFormValue[oldField.property],
                        newDefault
                    );

                    currentFormValue[oldField.property] = _.cloneDeep(newDefault);
                }
            });

            this.formService.setConfig(config, currentFormValue, this.contextObjectRef);
            this.abortController.signal.removeEventListener('abort', onAbort);
        });
    }

    async internalSubmit(goToDetail = false) {
        this.ea.publish('sio_form_pre_submit', {form: this});

        const value = Object.assign(this.params?.additionalValues || {}, this.formService.getValue());

        logger.debug('Submit with object', value);

        this._isSubmitting = true;

        if (!this.submit) {
            this.submit = this.submitHandler.apiSubmit.bind(this.submitHandler);
        }

        await this.submit({
            object: value,
            config: this.formService.config,
            identifier: this._objectIdentifier,
            context: this.contextObjectRef
        }).then(response => {
            this.ea.publish('sio_unregister_unsaved_changes', {changesKey: this});

            logger.debug('Received response', response);

            this._isSubmitting = false;

            if (this.params?.disableSubmitMessage == null && this.formService.rootConfig.labels.success && this.formService.rootConfig.labels.success !== '') {
                this.flash.success(this.formService.rootConfig.labels.success);
            }

            this.resetLocalValue();

            this.formContainer.dispatchEvent(new CustomEvent('sio-post-submit', {
                bubbles: true,
                detail: {response: response}
            }));

            if (this.formService.rootConfig.updateModelIds && this.formService.rootConfig.updateModelIds.length > 0) {
                this.formService.rootConfig.updateModelIds.forEach(modelId => {
                    this.ea.publish('sio_form_post_submit', {config: {modelId}});
                });
            }

            const config = Object.assign({}, this.formService.config);
            if (this.params) {
                config.controlUID = this.params.controlUID;
                config.actionContext = this.params.actionContext;
            }
            this.ea.publish('sio_form_post_submit', {config: config, response: response});

            if (goToDetail && this.displayView) {

                if ((!this._objectIdentifier && this.formService.rootConfig.showGoToDetail) || this.formService.rootConfig.alwaysShowGoToDetail) {
                    let parts = this.displayView.split('/');

                    this.router.navigateToRoute('view', {moduleId: parts[0], viewId: parts[1], id: response.data.id});
                }
            }
            return response;
        }, error => {

            this._isSubmitting = false;

            this.submitErrorHandler.handleError(error, this.formService);

            this.signaler.signal('sio_form_invalid');

            return error;
        }).finally(() => {
            this.signaler.signal('sio_form_submitted');
        });
    }
}
